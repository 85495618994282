import React, { useState, MouseEvent } from 'react'
import { Link } from 'react-router-dom';
import { makeStyles, createStyles } from '@material-ui/core';
import { Collapse, Drawer, Popover, List, ListItem, ListItemText } from '@material-ui/core';
import { ExpandLess, ExpandMore, Menu } from '@material-ui/icons';
import { useQuery } from '@apollo/client';
import { CATEGORY_MIN_QUERY, ICategoryMinList } from "../GraphQL/Categories";
import '../styles/Navigation.css';


const useStyles = makeStyles(() => createStyles({
  root: {
    width: '100%',
    maxWidth: '20rem'
  },
  paper: {
    backgroundColor: '#F4F1DE'
  }
}));
const useListStyles = makeStyles(() => createStyles({
  nested: {
    paddingLeft: '1rem'
  }
}));

const Navigation:React.FC = () => {
  const classes = useStyles();
  const listClasses = useListStyles();
  const [ open, setOpen ] = useState(false);
  const [ openSub, setOpenSub ] = useState(false);
  const [ dropdownElement, setDropdownElement ] = useState<HTMLDivElement|null>(null);

  const { data: gallery } = useQuery<ICategoryMinList>(CATEGORY_MIN_QUERY, {
    variables: {
      isModeling: false
    }
  });
  const { data: modeling } = useQuery<ICategoryMinList>(CATEGORY_MIN_QUERY, {
    variables: {
      isModeling: true
    }
  });

  const onToggle = () => setOpen(!open);
  const onToggleSub = () => setOpenSub(!openSub);
  const onMouseOver = (e:MouseEvent<HTMLDivElement>) => {
    e.currentTarget.classList.add('nav-hover');
    // Handle the mouseover for the modeling tab
    if (e.currentTarget.id === 'nav-modeling-link') {
      setDropdownElement(e.currentTarget);
    }
  }
  const onMouseOut = (e:MouseEvent<HTMLDivElement>) => {
    if (dropdown) {
      return;
    }
    e.currentTarget.classList.remove('nav-hover');
  }
  const onCloseDropdown = () => {
    if (dropdown) {
      dropdownElement.classList.remove('nav-hover');
      setDropdownElement(null);
    }
  }

  let dropdown = Boolean(dropdownElement);

  return (
    <div>

      <div id="nav-traditional" className="white">
        <div className="fl mr2 b ttu nav-link" onMouseOver={onMouseOver} onMouseOut={onMouseOut}>
          <Link to="/">Home</Link>
        </div>
        {gallery && gallery.categories.map((category) =>
          <div key={category.id} className="fl mh2 ttu nav-link" onMouseOver={onMouseOver} onMouseOut={onMouseOut}>
            <Link to={`/gallery/${category.slug}`}>
              {category.name}
            </Link>
          </div>
        )}
        <div id="nav-modeling-link" className="fl mh2 nav-link pointer" onMouseOver={onMouseOver} onMouseOut={onMouseOut}>
          <Link to="#">modeling</Link>
        </div>
        {modeling && (
        <Popover id="nav-dropdown-container" open={dropdown}
                 anchorEl={dropdownElement}
                 anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
                 transformOrigin={{vertical: 'top', horizontal: 'right'}}>
          <div onMouseLeave={onCloseDropdown}>
            <List>
              {modeling.categories.map((category) =>
                <Link key={category.id} to={`/gallery/${category.slug}`} onClick={onCloseDropdown}>
                  <ListItem button>
                    <ListItemText secondary={category.name} />
                  </ListItem>
                </Link>
              )}
            </List>
          </div>
        </Popover>
        )}
        <div className="fl mh2 nav-link" onMouseOver={onMouseOver} onMouseOut={onMouseOut}>
          <Link to="/about">about</Link>
        </div>
        <div className="fl mh2 nav-link" onMouseOver={onMouseOver} onMouseOut={onMouseOut}>
          <Link to="/contact">contact</Link>
        </div>
      </div>

      <button id="nav-drawer-button" className="flex items-center bn f4 white bg-transparent pointer" onClick={onToggle}>
        <Menu className="pl2" />
      </button>

      <Drawer anchor="right" open={open} onClose={onToggle} classes={classes}>
        <div id="nav-drawer-container" style={{minWidth: "20rem"}}>
          <List className={classes.root}>
            <Link to="/" onClick={onToggle}>
              <ListItem button>
                <ListItemText primary="Home" />
              </ListItem>
            </Link>
            {gallery && gallery.categories.map((category) =>
              <Link key={category.id} to={`/gallery/${category.slug}`} onClick={onToggle}>
                <ListItem button>
                  <ListItemText primary={category.name} />
                </ListItem>
              </Link>
            )}
            <ListItem button onClick={onToggleSub}>
              <ListItemText primary="Modeling" />
              {openSub ? <ExpandLess className="mr6" /> : <ExpandMore className="mr6" />}
            </ListItem>

            <Collapse in={openSub} timeout="auto" unmountOnExit>
              <List className={listClasses.nested}>
                {modeling && modeling.categories.map((category) =>
                  <Link key={category.id} to={`/gallery/${category.slug}`} onClick={onToggle}>
                    <ListItem button className="pl1">
                      <ListItemText secondary={category.name}/>
                    </ListItem>
                  </Link>
                )}
              </List>
            </Collapse>

            <Link to="/about" onClick={onToggle}>
              <ListItem button>
                <ListItemText primary="About" />
              </ListItem>
            </Link>
            <Link to="/contact" onClick={onToggle}>
              <ListItem button>
                <ListItemText primary="Contact" />
              </ListItem>
            </Link>
          </List>
        </div>
      </Drawer>

    </div>
  );
};

export default Navigation;
