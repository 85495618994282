import { gql } from "@apollo/client";

/**
 * Interfaces
 */

export interface ICategory {
  id: number,
  name: string,
  slug: string,
  isModeling: boolean,
  createdAt: string,
  updatedAt: string
}

export interface ICategoryList {
  categories: ICategory[]
}

export interface ICategoryMin {
  id: number,
  slug: string,
  name: string
}

export interface ICategoryMinList {
  categories: ICategoryMin[]
}

/**
 * Queries
 */

// Todo: Something to be done about this with fragments?

export const CATEGORY_MIN_QUERY = gql`
    query (
        $isModeling: Boolean!
    ) {
        categories (isModeling: $isModeling) {
            id
            slug
            name
        }
    }
`;

export const CATEGORY_LIST_QUERY = gql`
    query {
        categories {
            id
            name
            slug
            isModeling
            createdAt
            updatedAt
        }
    }
`;

export interface ICategoriesWithItems {
  categories: [{
    id: number
    slug: string
    name: string
    itemSet: [{
      thumbUrl: string
    }]
  }]
}
export const CATEGORIES_WITH_ITEMS = gql`
    query {
        categories (isModeling: false) {
            id
            slug
            name
            itemSet {
                thumbUrl
            }
        }
    }
`;

/**
 * Mutations
 */

export const CREATE_CATEGORY_MUTATION = gql`
    mutation CreateCategory (
        $name: String!
        $slug: String!
        $isModeling: Boolean!
    ) {
        createCategory (name: $name, slug: $slug, isModeling: $isModeling) {
            category {
                id
                name
                slug
                isModeling
                createdAt
                updatedAt
            }
        }
    }
`;

export interface ICreateCategoryResponse {
  createCategory: {
    category: ICategory
  }
}

export const UPDATE_CATEGORY_MUTATION = gql`
    mutation ($id: Int!, $isModeling: Boolean!) {
        updateCategory (id: $id, isModeling: $isModeling) {
            id
            isModeling
        }
    }
`;

export interface IUpdateCategoryResponse {
  updateCategory: ICategory
}

export const DELETE_CATEGORY_MUTATION = gql`
  mutation ($id: Int!) {
      deleteCategory (id: $id) {
          success
      }
  }
`;

export interface IDeleteCategoryResponse {
  success: boolean
}
