import React, { ChangeEvent, FormEvent, useRef, useState } from 'react';
import { useMutation } from '@apollo/client';
import {
  CATEGORY_LIST_QUERY,
  CATEGORY_MIN_QUERY,
  CREATE_CATEGORY_MUTATION,
  ICategoryList,
  ICategoryMinList,
  ICreateCategoryResponse
} from '../../GraphQL/Categories';
import { formatSlug } from '../Utils/Formatting';
import Splash from "../Utils/Splash";


const CreateCategory: React.FC = () => {

  const initialFormState = {
    name: '',
    slug: '',
    isModeling: false,
    error: false,
    errorMessage: ''
  };

  const [formState, setFormState] = useState(initialFormState);
  const [openSplash, setOpenSplash] = useState(false);
  const nameRef = useRef<HTMLInputElement>();

  const onChangeName = (e:ChangeEvent<HTMLInputElement>) => setFormState({
    ...formState,
    slug: formatSlug(e.target.value),
    name: e.target.value
  });

  const onChangeSlug = (e:ChangeEvent<HTMLInputElement>) => setFormState({
    ...formState,
    slug: formatSlug(e.target.value)
  });

  const onChangeIsModeling = (e:ChangeEvent<HTMLInputElement>) => setFormState({
    ...formState,
    isModeling: !!e.target.value
  });

  const [createCategory] = useMutation<ICreateCategoryResponse>(CREATE_CATEGORY_MUTATION, {
    variables: {
      name: formState.name,
      slug: formState.slug,
      isModeling: formState.isModeling
    },
    onError: (error) => {
      setFormState({
        ...formState,
        error: true,
        errorMessage: error.message
      });
    },
    onCompleted: () => {
      setFormState(initialFormState);
      setOpenSplash(true);
      setTimeout(() => { setOpenSplash(false); }, 2000);
    },
    update: (cache, { data }) => {
      // Fail early if the mutation wasn't successful
      if (!data) {
        return;
      }

      const isModeling = formState.isModeling;

      // Pull the cached categories query from Apollo
      let cached = cache.readQuery<ICategoryMinList>({
        query: CATEGORY_MIN_QUERY,
        variables: {
          isModeling: isModeling
        }
      });

      // Todo: Can sortOrder be maintained after the category query is re-run?

      // Write the new category into the cache, based on the data.createCategory.category
      cache.writeQuery({
        query: CATEGORY_MIN_QUERY,
        data: {
          categories: [
            ...cached.categories,
            data.createCategory.category
          ]
        },
        variables: {
          isModeling: isModeling
        }
      });

      // Do the same for the detailed list
      cached = cache.readQuery<ICategoryList>({query: CATEGORY_LIST_QUERY});
      cache.writeQuery({
        query: CATEGORY_LIST_QUERY,
        data: {
          categories: [
            ...cached.categories,
            data.createCategory.category
          ]
        }
      });
    }
  });

  const onSubmit = (e:FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setFormState({
      ...formState,
      error: false,
      errorMessage: ''
    });
    createCategory().then(() => {
      nameRef.current.focus();
    });
  };

  return (
    <div className="flex w-33 center">
      <form className="w-100" onSubmit={onSubmit}>
        {formState.error && (
          <div>
            <b className='red'>{formState.errorMessage}</b>
          </div>
        )}
        <div className="flex flex-column mt3">
          <input ref={nameRef}
                 className="w-100 mb2"
                 type="text"
                 value={formState.name}
                 onChange={onChangeName}
                 placeholder="Display Name"
                 autoFocus={true}
                 required={true} />
          <input className="w-100 mb2"
                 type="text"
                 value={formState.slug}
                 onChange={onChangeSlug}
                 placeholder="Slug (short URL)"
                 required={true} />
          <div className="w-100">
            <div className="fl mr2">
              <input className="mb2"
                     type="checkbox"
                     checked={formState.isModeling}
                     onChange={onChangeIsModeling}
                     placeholder="Is Modeling" />
            </div>
            <div className="fl">
              Is Modeling?
            </div>
            <button className="fr" type="submit">Submit</button>
          </div>
        </div>
      </form>
      <Splash open={openSplash}>
        <div className="b green">Category created!</div>
      </Splash>
    </div>
  );
};

export default CreateCategory;
