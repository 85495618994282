import React from 'react';
import {
  ErrorOutline,
  ReportProblemOutlined,
  InfoOutlined,
  CheckCircleOutlined
} from "@material-ui/icons";


interface Lookup {
  [key:string]: string
}
const Colors:Lookup = {
  'error':   'red',
  'warning': 'orange',
  'info':    'blue',
  'success': 'green'
};

interface AlertProps {
  severity: string
}
const Alert:React.FC<AlertProps> = (props) => {
  const color = Colors[props.severity];
  return (
    <div className="flex items-center w-100 mt2 mb3 pv2 ph3 white fw4" style={{
      backgroundColor: color,
      boxShadow: "4px 4px 8px rgba(0, 0, 0, 0.25)",
      borderRadius: "8px"
    }}>
      <div className="fl mr2">
        {props.severity === 'error' && <ErrorOutline />}
        {props.severity === 'warning' && <ReportProblemOutlined />}
        {props.severity === 'info' && <InfoOutlined />}
        {props.severity === 'success' && <CheckCircleOutlined />}
      </div>
      <div className="fl">
        {props.children}
      </div>
    </div>
  );
}

export default Alert;
