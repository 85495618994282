import React, { useRef } from 'react';
import { useHistory } from 'react-router-dom';
import '../styles/HomeTile.css';


class Colors {
  private static Lookup = [
    "#6FC3DF",
    "#F2CC8F",
    "#E07A5F",
    "#81B29A",
    "#C9ADA7",
    "#892B64",
    "#2E6F95",
  ];

  public static Get(index:number): string {
    const i = index % this.Lookup.length;
    return this.Lookup[i];
  }
}

interface HomeTileProps {
  index: number
  category: {
    id: number
    slug: string
    name: string
  }
  thumbUrl: string
}
const HomeTile:React.FC<HomeTileProps> = (props) => {

  const history = useHistory();
  const maskRef = useRef<HTMLDivElement>();

  const OnMouseOver = () => maskRef.current.classList.add('fade');
  const OnMouseLeave = () => maskRef.current.classList.remove('fade');
  const OnClick = () => history.push(`/gallery/${props.category.slug}`);

  const width = ([1, 2].indexOf(props.index % 4) !== -1) ? 60 : 40;
  const bgImage = `url(${props.thumbUrl})`;
  return (
    <div className={`HomeTile flex items-center justify-center w-100 w-${width}-l`}
         style={{
           height: 430,
           backgroundImage: bgImage,
           backgroundSize: "cover",
           backgroundPosition: "center"
         }}
         onMouseOver={OnMouseOver}
         onMouseLeave={OnMouseLeave}
         onClick={OnClick}>
      <div ref={maskRef} className="mask" style={{backgroundColor: Colors.Get(props.index)}} />
      <div className="label flex f1 fw2 white">{props.category.name}</div>
    </div>
  );
};

export default HomeTile;
