/**
 * Constants which will be used throughout the application
 */

// Key used to store the JWT in sessionStorage
export const AUTH_TOKEN = 'auth-token';

// Tracking code
export const GA_TRACKING_CODE = (process.env.REACT_APP_ENV === 'production')
  ? 'UA-142223769-2'
  : null;

// Endpoint for the GraphQL API
const endpoints:Record<string, string> = {
  'local': 'http://api.theuntitledarts.local',
  'development': 'https://api-dev.theuntitledarts.com',
  'production': 'https://api.theuntitledarts.com'
};
export const API_URI = endpoints[process.env.REACT_APP_ENV];
