import React, { ChangeEvent, KeyboardEvent, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useMutation, gql } from '@apollo/client';
import { Location } from 'history'
import { useAuth } from '../../Admin';


const LOGIN_MUTATION = gql`
  mutation LoginMutation(
      $username: String!
      $password: String!
  ) {
      tokenAuth (
          username: $username
          password: $password
      ) {
          token
      }
  }
`;

const Login: React.FC = () => {
  const history = useHistory();
  const location = useLocation<Location>();
  const auth = useAuth();

  const { pathname } = location.state || { pathname: '/admin' };

  const [formState, setFormState] = useState({
    error: false,
    errorMessage: '',
    username: '',
    password: '',
  });

  const onChangeUsername = (e:ChangeEvent<HTMLInputElement>) => setFormState({
    ...formState,
    username: e.target.value
  });

  const onChangePassword = (e:ChangeEvent<HTMLInputElement>) => setFormState({
    ...formState,
    password: e.target.value
  });

  const onPasswordKeyUp = (e:KeyboardEvent) => {
    if (e.key === 'Enter') {
      onSubmit();
    }
  };

  const [loginMutation] = useMutation(LOGIN_MUTATION, {
    variables: {
      username: formState.username,
      password: formState.password
    },
    onError: (error) => {
      // Pass the error on to the view
      setFormState({
        ...formState,
        error: true,
        errorMessage: error.message
      });
    },
    onCompleted: ({tokenAuth}) => {
      auth.login(tokenAuth.token);
      history.replace(pathname);
    }
  });

  const onSubmit = () => {
    // Clear any errors
    setFormState({
      ...formState,
      error: false,
      errorMessage: ''
    });

    // Perform the login
    loginMutation().then(() => {});
  };

  return (
    <div className="flex">
      <div className="w-25 center">
        {formState.error && (
          <div>
            <b>Error: </b>{formState.errorMessage}
          </div>
        )}
        <h4 className="mv2">Log In:</h4>
        <div className="mv2">
          <input type="text"
                 className="w-100"
                 value={formState.username}
                 placeholder="Username"
                 onChange={onChangeUsername}
          />
        </div>
        <div className="mv2">
          <input type="password"
                 className="w-100"
                 value={formState.password}
                 placeholder="Password"
                 onChange={onChangePassword}
                 onKeyUp={onPasswordKeyUp}
          />
        </div>
        <div className="mv2 tr">
          <button className="pointer button" onClick={onSubmit}>Submit</button>
        </div>
      </div>
    </div>
  );
};

export default Login;
