import { gql } from "@apollo/client";
import { ICategoryMin } from './Categories';

/**
 * Interfaces
 */

export interface IItem {
  id: number,
  name: string,
  slug: string,
  description: string,
  category: ICategoryMin,
  fullUrl: string,
  thumbUrl: string,
  createdAt: string,
  updatedAt: string
}

export interface IItemList {
  items: IItem[]
}

export interface IItemMin {
  id: number,
  slug: string,
  name: string,
  fullUrl: string,
  thumbUrl: string
}

export interface IItemMinList {
  items: IItemMin[]
}

/**
 * Queries
 */

// Todo: Something to be done about this with fragments?

export const ITEM_MIN_QUERY = gql`
    query (
        $categoryId: Int
        $categorySlug: String
    ) {
        items (categoryId: $categoryId, categorySlug: $categorySlug) {
            id
            slug
            name
            fullUrl
            thumbUrl
        }
    }
`;

export const ITEM_LIST_QUERY = gql`
    query {
        items {
            id
            name
            slug
            description
            category {
                id
                name
            }
            fullUrl
            thumbUrl
            createdAt
            updatedAt
        }
    }
`;

/**
 * Mutations
 */
