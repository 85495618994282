import React, { useEffect, useState } from 'react';
import {
  makeStyles,
  createStyles,
  GridList,
  GridListTile,
  Dialog,
  DialogContent
} from '@material-ui/core';
import { useParams } from "react-router";
import { Link, useHistory } from "react-router-dom";
import { gql, useQuery } from "@apollo/client";
import { ITEM_MIN_QUERY, IItemMinList } from './GraphQL/Items';

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexWrap: 'wrap',
      marginTop: '3px',
      justifyContent: 'space-around',
      overflow: 'hidden'
    },
    list: {
      width: '100%',
      transform: 'translateZ(0)'
    },
    tile: {
      height: '20rem'
    },
    item: {
      width: '100%',
      height: '20rem',
      backgroundSize: 'cover',
      backgroundPosition: 'center'
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#FFF'
    }
  })
);

interface ICategoryName {
  category: {
    name: string
  }
}
const CATEGORY_NAME_QUERY = gql`
  query ($slug: String!){
      category (slug: $slug) {
          name
      }
  }
`;

interface GalleryParams {
  slug: string
  itemSlug: string
}
const Gallery: React.FC = () => {
  const classes = useStyles();
  const { slug, itemSlug } = useParams<GalleryParams>();
  const history = useHistory();
  const [ open, setOpen ] = useState(false);
  const [ dialogUrl, setDialogUrl ] = useState('#');
  const { data: nameData } = useQuery<ICategoryName>(CATEGORY_NAME_QUERY, {
    variables: {
      slug: slug
    }
  });
  const { data, loading } = useQuery<IItemMinList>(ITEM_MIN_QUERY, {
    variables: {
      categorySlug: slug
    }
  });

  const onClick = (fullUrl:string) => {
    setDialogUrl(fullUrl);
    setOpen(true);
  }
  const onClose = (modifyHistory:boolean = true) => {
    setOpen(false);
    if (modifyHistory) {
      history.push(`/gallery/${slug}`);
    }
  }

  useEffect(() => {
    if (itemSlug) {
      if (!loading) {
        data.items.forEach((item) => {
          if (item.slug === itemSlug) {
            onClick(item.fullUrl);
          }
        });
      }
    } else {
      setOpen(false);
    }
  }, [itemSlug, loading, data, history]);

  return (
    <>
    {nameData && (
      <h2 className="pl3 pv2 white">{nameData.category.name}</h2>
    )}
    <div className={classes.root}>
      {data && (
        <GridList className={classes.list} cellHeight='auto' cols={3}>
          {data.items.map((item) => {
            return (
              <GridListTile key={item.id} className={classes.tile} cols={1}>
                <Link to={`/gallery/${slug}/${item.slug}`} onClick={() => onClick(item.fullUrl)}>
                  <div className={classes.item} style={{ backgroundImage: `url(${item.thumbUrl})` }} />
                </Link>
              </GridListTile>
            )}
          )}
        </GridList>
      )}
      <Dialog open={open} onClose={onClose} maxWidth={false}>
        <DialogContent>
          <img src={dialogUrl} alt="Full size version of artwork" />
        </DialogContent>
      </Dialog>
    </div>
    </>
  );
};

export default Gallery;
