import React, {useState, ChangeEvent, FormEvent} from 'react';
import { Button } from '@material-ui/core';
import Alert from './components/Alert';
import { API_URI } from "./constants";
import './styles/Contact.css'


interface ContactFormResponse {
  status: number,
  message?: string
}
const Contact:React.FC = () => {

  const initialFormState = {
    name: '',
    email: '',
    message: '',
    success: false,
    error: false,
    errorMessage: ''
  };
  const [ formState, setFormState ] = useState(initialFormState);

  const OnChangeName = (e:ChangeEvent<HTMLInputElement>) => setFormState({
    ...formState,
    name: e.target.value
  });
  const OnChangeEmail = (e:ChangeEvent<HTMLInputElement>) => setFormState({
    ...formState,
    email: e.target.value
  });
  const OnChangeMessage = (e:ChangeEvent<HTMLTextAreaElement>) => setFormState({
    ...formState,
    message: e.target.value
  });

  const OnSubmit = (e:FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setFormState({
      ...formState,
      error: false,
      errorMessage: ''
    });

    const data = new FormData();
    data.set('name', formState.name);
    data.set('email', formState.email);
    data.set('message', formState.message);

    fetch(API_URI + '/contact', {
      method: 'POST',
      body: data
    }).then(response => response.json())
      .then((json:ContactFormResponse) => {
        if (json.status === 200) {
          // Todo: Success messaging on item upload
          setFormState({
            ...initialFormState,
            success: true
          });
        } else {
          setFormState({
            ...formState,
            error: true,
            errorMessage: json.message
          });
        }
      }).catch(() => {
      setFormState({
        ...formState,
        error: true,
        errorMessage: 'There was an error sending the message! Please email: theuntitledarts@gmail.com'
      });
    });
  };

  return (
    <div className="Contact flex-l w-80 center mv5 white fw2">

      <div className="w-50-l">
        <div className="mv2"><b><u>Artist</u></b></div>
        <div>Shay Page</div>
        <div className="mt4"><b><u>Email</u></b></div>
        <div className="mv2">
          <div>theuntitledarts@gmail.com</div>
        </div>
      </div>

      <div className="w-50-l mt0 mt4-m">
        {formState.success && (
          <Alert severity="success">Thank you for your message!</Alert>
        )}
        {formState.error && (
          <Alert severity="error">Error: {formState.errorMessage}</Alert>
        )}
        <form onSubmit={OnSubmit}>
          <div className="mv2">
            <input className="input-reset w-100" placeholder="Your Name" value={formState.name} required={true} onChange={OnChangeName} />
          </div>
          <div className="mv2">
            <input className="input-reset w-100" placeholder="Your Email Address" value={formState.email} required={true} onChange={OnChangeEmail} />
          </div>
          <div className="mv2">
            <textarea className="input-reset w-100" placeholder="What's on you mind?" value={formState.message} required={true} onChange={OnChangeMessage} />
          </div>
          <div className="w-100 mv2 cf">
            <Button className="fr" type="submit" variant="contained" color="primary">Submit</Button>
          </div>
        </form>
      </div>

    </div>
  );
};

export default Contact;
