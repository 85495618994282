import { gql } from '@apollo/client';

/**
 * Used to validate queries and mutations client-side
 * This is generated by the Django command: ./manage.py graphql-schema
 */
const schema = gql`
    schema {
        query: Query
        mutation: Mutation
    }

    type CategoryType {
        id: ID!
        slug: String!
        name: String!
        isModeling: Boolean!
        createdAt: DateTime!
        updatedAt: DateTime!
        itemSet: [ItemType!]!
    }

    type CreateCategory {
        category: CategoryType
    }

    scalar DateTime

    type DeleteCategory {
        success: Boolean
    }

    type DeleteItem {
        success: Boolean
    }

    scalar GenericScalar

    type ItemType {
        id: ID!
        slug: String!
        category: CategoryType!
        name: String!
        description: String!
        fullUrl: String!
        thumbUrl: String!
        createdAt: DateTime!
        updatedAt: DateTime!
    }

    type Mutation {
        tokenAuth(username: String!, password: String!): ObtainJSONWebToken
        verifyToken(token: String): Verify
        refreshToken(token: String): Refresh
        createCategory(isModeling: Boolean, name: String, slug: String): CreateCategory
        updateCategory(id: Int, isModeling: Boolean, name: String, slug: String): UpdateCategory
        deleteCategory(id: Int): DeleteCategory
        deleteItem(id: Int): DeleteItem
    }

    type ObtainJSONWebToken {
        payload: GenericScalar!
        refreshExpiresIn: Int!
        token: String!
    }

    type Query {
        me: UserType
        categories(isModeling: Boolean): [CategoryType]
        category(id: Int, slug: String): CategoryType
        items(categoryId: Int, categorySlug: String): [ItemType]
        item(id: Int!): ItemType
    }

    type Refresh {
        payload: GenericScalar!
        refreshExpiresIn: Int!
        token: String!
    }

    type UpdateCategory {
        id: Int
        name: String
        slug: String
        isModeling: Boolean
    }

    type UserType {
        id: ID!
        password: String!
        lastLogin: DateTime
        isSuperuser: Boolean!
        username: String!
        firstName: String!
        lastName: String!
        email: String!
        isStaff: Boolean!
        isActive: Boolean!
        dateJoined: DateTime!
    }

    type Verify {
        payload: GenericScalar!
    }
`;

export default schema;
