import React, { useRef } from 'react';
import { Transition } from "react-transition-group";
import '../styles/SaveArrow.css';


const defaultStyles = {
  transition: 'opacity 300ms ease-in-out',
  opacity: 1
};

const transitionStyles = {
  entering: { opacity: 1 },
  entered: { opacity: 1 },
  exiting: { opacity: 0 },
  exited: { opacity: 0},
  unmounted: { display: 'none' }
};

interface SaveArrowProps {
  targetRef: React.MutableRefObject<HTMLInputElement>,
  trigger: boolean
}
const SaveArrow:React.FC<SaveArrowProps> = (props) => {
  const ref = useRef<HTMLDivElement>();

  const getPosition = () => {
    if (!props.targetRef.current) {
      return { x: 0, y: 0 };
    }

    let { x, y, width } = props.targetRef.current.getBoundingClientRect();

    // Fiddle it a bit
    x += window.scrollX + width + 10; // Todo: Flip to the left of the element if on the edge of the window
    y += window.scrollY - 6

    return { x: x, y: y };
  };

  const getHeight = () => {
    return (ref.current)
      ? ref.current.getBoundingClientRect().height
      : 0;
  }

  return (
    <Transition in={props.trigger} timeout={300} nodeRef={props.targetRef}>
      {(state) => {

        const { x, y } = getPosition();
        const borderWidth = getHeight() * 0.5;

        return (
          <div style={{
            position: 'absolute',
            top: y,
            left: x,
            ...defaultStyles,
            ...transitionStyles[state]
          }}>
            <div className="fl arrow-left" style={{borderWidth: borderWidth}} />
            <div ref={ref} className="fl pa2 b white bg-green">
              Saved!
            </div>
          </div>
        );
      }}
    </Transition>
  );
};

export default SaveArrow;
