import React from 'react';
import Navigation from './Navigation';
import '../styles/Header.css';


const Header:React.FC = () => {
  return (
    <div id="Header" className="fixed left-0 top-0 right-0 ph3">

      <div className="cf flex justify-between items-center" style={{height: "5rem"}}>

        <div className="w-25">
          <div className="fl f3 nowrap white">
            The Untitled Arts
          </div>
        </div>

        <div className="w-75">
          <div className="fr">
            <Navigation />
          </div>
        </div>

      </div>
    </div>
  );
};

export default Header;
