import React from 'react';
import CategoryTable from "./CategoryTable";
import CreateCategory from "./CreateCategory";


const ManageCategories: React.FC = () => {
  return (
    <div>

      <h2>Create Category</h2>
      <CreateCategory />

      <h2>Manage Categories</h2>
      <CategoryTable />

    </div>
  );
};

export default ManageCategories;
