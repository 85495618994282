import React, { ChangeEvent, useRef, useState } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import {
  Button, Switch,
  Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle
} from '@material-ui/core';
import DeleteForeverOutlinedIcon from '@material-ui/icons/DeleteForeverOutlined';
import SaveArrow from '../SaveArrow';
import {
  CATEGORY_LIST_QUERY,
  CATEGORY_MIN_QUERY,
  UPDATE_CATEGORY_MUTATION,
  DELETE_CATEGORY_MUTATION,
  ICategory,
  ICategoryList,
  IUpdateCategoryResponse,
  IDeleteCategoryResponse
} from '../../GraphQL/Categories';
import { formatDate } from '../Utils/Formatting';


interface CategoryRowProps {
  category: ICategory
}
const CategoryRow: React.FC<CategoryRowProps> = (props) => {
  const category = props.category;
  const ref = useRef<HTMLInputElement>();

  const [ rowState, setRowState ] = useState(category);
  const [ showSaved, setShowSaved ] = useState(false);
  const [ showDelete, setShowDelete ] = useState(false);

  const [ updateMutation ] = useMutation<IUpdateCategoryResponse>(UPDATE_CATEGORY_MUTATION);
  const [ deleteMutation ] = useMutation<IDeleteCategoryResponse>(DELETE_CATEGORY_MUTATION);

  const onChangeModeling = (e:ChangeEvent<HTMLInputElement>) => {
    const isModeling = e.target.checked;

    setRowState({
      ...rowState,
      isModeling: isModeling
    });

    updateMutation({
      variables: {
        id: props.category.id,
        isModeling: isModeling
      },
      refetchQueries: [
        { query: CATEGORY_MIN_QUERY, variables: { isModeling: false }},
        { query: CATEGORY_MIN_QUERY, variables: { isModeling: true }},
      ]
    }).then(() => {
      setShowSaved(true);
      setTimeout(() => {
        setShowSaved(false);
      }, 2000);
    });
  };

  const onDeleteOpen = () => {
    setShowDelete(true);
  };
  const onDeleteCancel = () => {
    setShowDelete(false);
  };
  const onDeleteConfirm = () => {
    deleteMutation({
      variables: {
        id: category.id
      }, refetchQueries: [
        { query: CATEGORY_MIN_QUERY, variables: { isModeling: false }},
        { query: CATEGORY_MIN_QUERY, variables: { isModeling: true }},
        { query: CATEGORY_LIST_QUERY }
      ]
    }).then(() => {
      setShowDelete(false);
    });
  };

  return (
    <tr key={category.id} className="tl striped--near-white">
      <td className="pv2 ph3">{category.name}</td>
      <td className="pv2 ph3">{category.slug}</td>
      <td className="tc ph1">
        <span ref={ref}>
          <Switch
            color="primary"
            checked={rowState.isModeling}
            onChange={onChangeModeling}
          />
        </span>
        <SaveArrow targetRef={ref} trigger={showSaved} />
      </td>
      <td className="pv2 ph3">{formatDate(category.createdAt)}</td>
      <td className="pv2 ph3">{formatDate(category.updatedAt)}</td>
      <td className="tc pv2 ph1">
        <span className="red pointer">
          <DeleteForeverOutlinedIcon onClick={onDeleteOpen} />
          <Dialog
              open={showDelete}
              onClose={onDeleteCancel}
              aria-labelledby="delete-dialog-label"
              aria-describedby="delete-dialog-content">
            <DialogTitle id="delete-dialog-label">{`Delete category: "${category.name}"?`}</DialogTitle>
            <DialogContent id="delete-dialog-content">
              <DialogContentText>
                <span className="b red">This action cannot be undone!</span>
              </DialogContentText>
              <DialogContentText>
                <span>This will also delete every item uploaded under the category.</span>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={onDeleteCancel} color="primary">Cancel</Button>
              <Button onClick={onDeleteConfirm} color="primary">Confirm</Button>
            </DialogActions>
          </Dialog>
        </span>
      </td>
    </tr>
  );
};

const CategoryTable: React.FC = () => {
  const { data } = useQuery<ICategoryList>(CATEGORY_LIST_QUERY);
  return (
    <div className="flex justify-center">
      <form>
        <table className="collapse ba br2 b--black-10 pv2 ph3">
          <thead>
            <tr className="tl">
              <th className="pv2 ph3">Name</th>
              <th className="pv2 ph3">Slug</th>
              <th className="pv2 ph1">Modeling</th>
              <th className="pv2 ph3">Created</th>
              <th className="pv2 ph3">Updated</th>
              <th className="pv2 ph1">Delete?</th>
            </tr>
          </thead>
          <tbody>
          { data &&
            data.categories.map((category: ICategory) =>
              <CategoryRow key={category.id} category={category} />
          )}
          </tbody>
        </table>
      </form>
    </div>
  );
};

export default CategoryTable;
