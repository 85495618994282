import React, { ChangeEvent, FormEvent, useRef, useState } from 'react';
import {
  CATEGORY_MIN_QUERY,
  ICategoryMinList
} from '../../GraphQL/Categories';
import { useQuery } from '@apollo/client';
import { formatSlug } from '../Utils/Formatting';
import { API_URI } from '../../constants';


interface UploadItemResponse {
  status: number,
  item: number,
  message: string
}

const CreateItem:React.FC = () => {

  const initialFormState = {
    name: '',
    category: 0,
    slug: '',
    description: '',
    error: false,
    errorMessage: ''
  };

  const [ formState, setFormState ] = useState(initialFormState);
  const [ fileState, setFileState ] = useState({
    selectedFile: null,
    loaded: 0
  });

  const nameRef = useRef<HTMLInputElement>();

  const { data: categoryData } = useQuery<ICategoryMinList>(CATEGORY_MIN_QUERY, {
    variables: {
      isModeling: false
    },
    onCompleted: (result) => setFormState({
      ...formState,
      category: result?.categories[0]?.id
    })
  });
  const { data: modelingData } = useQuery<ICategoryMinList>(CATEGORY_MIN_QUERY, {
    variables: {
      isModeling: true
    }
  });

  const onChangeName = (e:ChangeEvent<HTMLInputElement>) => setFormState({
    ...formState,
    name: e.target.value,
    slug: formatSlug(e.target.value)
  });

  const onChangeSlug = (e:ChangeEvent<HTMLInputElement>) => setFormState({
    ...formState,
    slug: formatSlug(e.target.value)
  });

  const onChangeDescription = (e:ChangeEvent<HTMLTextAreaElement>) => setFormState({
    ...formState,
    description: e.target.value
  });

  const onChangeCategory = (e:ChangeEvent<HTMLSelectElement>) => setFormState({
    ...formState,
    category: parseInt(e.target.value)
  });

  const onChangeFile = (e:ChangeEvent<HTMLInputElement>) => setFileState({
    ...fileState,
    selectedFile: e.target.files[0]
  });

  const onSubmit = (e:FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setFormState({
      ...formState,
      error: false,
      errorMessage: ''
    });

    // We don't use GraphQL here, instead we do a normal AJAX POST
    const data = new FormData();
    data.set('name', formState.name);
    data.set('categoryId', formState.category.toString());
    data.set('slug', formState.slug);
    data.set('description', formState.description);
    data.set('file', fileState.selectedFile);

    fetch(API_URI + '/upload', {
      method: 'POST',
      body: data
    }).then(response => response.json())
      .then((json:UploadItemResponse) => {
        if (json.status === 200) {
          // Todo: Success messaging on item upload
          setFormState({
            ...initialFormState,
            category: formState.category
          });
        } else {
          setFormState({
            ...formState,
            error: true,
            errorMessage: json.message
          });
        }
    }).catch(() => {
      setFormState({
        ...formState,
        error: true,
        errorMessage: 'There was an upload error'
      });
    });
  };

  return (
    <div className="flex w-33 center">
      <form className="w-100" onSubmit={onSubmit}>
        {formState.error && (
          <div>
            <b className='red'>{formState.errorMessage}</b>
          </div>
        )}
        <input ref={nameRef}
               className="w-100 mb2"
               type="text"
               value={formState.name}
               onChange={onChangeName}
               placeholder="Display Name"
               autoFocus={true} />
        <input className="w-100 mb2"
               type="text"
               value={formState.slug}
               onChange={onChangeSlug}
               placeholder="Slug (short URL)"
               required={true} />
        <textarea className="w-100 mb2"
                  value={formState.description}
                  onChange={onChangeDescription}
                  placeholder="Item Description" />

        <input className="w-100 mb2"
               type="file"
               name="file"
               required={true}
               onChange={onChangeFile} />

        <div className="flex justify-between">
          <div className="w-50">
            {categoryData && modelingData && (
            <select onChange={onChangeCategory}>
              <option className="b" disabled={true} selected={true}>--- Art ---</option>
              {categoryData.categories.length > 0 && categoryData.categories.map((category) => (
                <option key={category.id} value={category.id}>{category.name}</option>
              ))}
              <option disabled={true} />
              <option className="b" disabled={true}>--- Modeling ---</option>
              {modelingData.categories.length > 0 && modelingData.categories.map((category) => (
                <option key={category.id} value={category.id}>{category.name}</option>
              ))}
            </select>
            )}
          </div>
          <div className="w-50">
            <button type="submit">Submit</button>
          </div>
        </div>

      </form>
    </div>
  );
};

export default CreateItem;
