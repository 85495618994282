import React from 'react';
import { useQuery } from '@apollo/client';
import { ITEM_LIST_QUERY, IItemList, IItem } from '../../GraphQL/Items';
import { formatDate } from "../Utils/Formatting";


interface ItemRowProps {
  item: IItem
}
const ItemRow:React.FC<ItemRowProps> = (props) => {
  const item = props.item;
  return (
    <tr key={item.id} className="tl striped--near-white">
      <td className="pv2 ph3">{item.name}</td>
      <td className="pv2 ph3">{item.slug}</td>
      <td className="pv2 ph3">{item.category.name}</td>
      <td className="pv2 ph3">{formatDate(item.createdAt)}</td>
      <td className="pv2 ph3">{formatDate(item.updatedAt)}</td>
      <td className="tc pv2 ph1" />
    </tr>
  );
};

const ItemTable:React.FC = () => {

  const { data } = useQuery<IItemList>(ITEM_LIST_QUERY);

  return (
    <div className="flex justify-center">
      <form>
        <table className="collapse ba br2 b--black-10 pv2 ph3">
          <thead>
          <tr className="tl">
            <th className="pv2 ph3">Name</th>
            <th className="pv2 ph3">Slug</th>
            <th className="pv2 ph3">Category</th>
            <th className="pv2 ph3">Created</th>
            <th className="pv2 ph3">Updated</th>
            <th className="pv2 ph1" />
          </tr>
          </thead>
          <tbody>
          { data && data.items.map((item) =>
            <ItemRow key={item.id} item={item} />
          )}
          </tbody>
        </table>
      </form>
    </div>
  );
};

export default ItemTable;
