import React, { createContext, useContext, useState } from 'react';
import { Link, Redirect, Route, RouteProps, Switch, useRouteMatch } from 'react-router-dom';
import Login from './components/Admin/Login'
import { AUTH_TOKEN } from './constants';
import ManageCategories from './components/Admin/ManageCategories';
import ManageItems from './components/Admin/ManageItems';
import './styles/Admin.css';


const PrivateRoute = ({ children, ...rest }:RouteProps) => {
  const auth = useAuth();
  return (
    <Route
      {...rest}
      render={({location}) =>
        auth.loggedIn ? (
          children
        ) : (
          <Redirect to={{
            pathname: "/admin/login",
            state: { from: location }
          }} />
    )} />
  );
};

interface AuthInterface {
  loggedIn: boolean,
  login: (token: string) => void,
  logout: () => void
}

// The <Partial<AuthInterface>> generics help create default values
const authContext = createContext<Partial<AuthInterface>>({});

export const AuthProvider = authContext.Provider;

// This returns the most recent copy of the auth context
export const useAuth = () => {
  return useContext(authContext);
};

// This returns an object that implements the AuthInterface
export const useProvideAuth = (): AuthInterface => {
  // We can use this to persist the session between visits
  const persistLogin = !!sessionStorage.getItem(AUTH_TOKEN);

  // Uses the state to maintain the currently logged in state
  const [loggedIn, setLoggedIn] = useState(persistLogin);

  const login = (token: string) => {
    sessionStorage.setItem(AUTH_TOKEN, token);
    setLoggedIn(true);
  };
  const logout = () => {
    sessionStorage.removeItem(AUTH_TOKEN);
    setLoggedIn(false);
  };

  return {
    loggedIn,
    login,
    logout
  };
};

interface IAdminLink {
  to: string,
  label: string
}

const AdminLink: React.FC<IAdminLink> = (props) => {
  return (
    <div className="AdminLink outline gray bg-dark-gray mh1 pa1">
      <Link to={props.to}>{props.label}</Link>
    </div>
  );
};

const Admin: React.FC = () => {
  const { path, url } = useRouteMatch('/admin');
  const auth = useAuth(); // authContext

  return (
    <div>

      <header>
        <div className="flex justify-center mt2">
          {auth.loggedIn ? (
            <>
              <AdminLink to={`${url}`} label="Admin Home" />
              <AdminLink to={`${url}/manage-categories`} label="Manage Categories" />
              <AdminLink to={`${url}/manage-items`} label="Manage Items" />
              <div onClick={auth.logout}>
                <AdminLink to="#" label="Log Out" />
              </div>
            </>
          ) : (
            <AdminLink to={`${url}/login`} label="Login" />
          )}
        </div>
      </header>

      <Switch>
        <Route exact path={path}>
          <div className="tc mv5">
            This is the admin portal. Select an option from above.
          </div>
        </Route>
        <Route exact path={`${path}/login`}>
          <Login/>
        </Route>
        <PrivateRoute exact path={`${path}/manage-categories`}>
          <ManageCategories />
        </PrivateRoute>
        <PrivateRoute exact path={`${path}/manage-items`}>
          <ManageItems />
        </PrivateRoute>
      </Switch>

    </div>
  );
};

export default Admin;
