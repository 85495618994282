import React from 'react';
import CreateItem from './CreateItem';
import ItemTable from './ItemTable';


const ManageItems:React.FC = () => {

  return (
    <div>

      <h2>Upload Item</h2>
      <CreateItem />

      <h2>Manage Items</h2>
      <ItemTable />

    </div>
  );
};

export default ManageItems;
