import React from 'react';
import '../styles/Footer.css'


const Footer: React.FC = () => {
  return (
    <div id="Footer" className="mt3 pa4 white bt">
        &copy;2021 The Untitled Arts, LLC
    </div>
  );
};

export default Footer;
