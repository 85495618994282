import React from 'react';
import ImgHeadshot from './images/headshot.jpg';


const About:React.FC = () => {
  return (
    <div className="flex-l mt4">
      <div className="w-50-l ph1">
        <img src={ImgHeadshot} alt="Headshot of Shay Page" />
      </div>
      <div className="w-50-l ph4 pb2 white fw2">
        <h2>Shay Page</h2>
        <h3>Freelance Artist</h3>
        <p>I enjoy many forms of art, such as: design, painting, digital drawing, crafting, costume making, modeling, photography, and music.</p>
        <p>I have always been a graphite artist, starting way back in my childhood. Portraits are my favorite to draw, I think the human body is beautiful and each person has a uniqueness that I like to capture with my art.</p>
        <p>-</p>
        <p>I am an eager and creative illustrator who is always looking for new was to bring art into this world. I attended the Art Institute of Seattle for a year, after my first year I left to peruse more freelance based projects. For the past few years I have been running my own business as a children's book illustrator. I have loved helping many new authors bring their stories to life. Check out <a className="gray underline" href="https://illustratemybook.com">illustratemybook.com</a> to learn more about my book Illustrations!</p>
        <p>Throughout my life I have worked with many small businesses and clients to create new and exciting designs. During my time in school and my own interest in art I have grown very comfortable with the Adobe Suite, along with other illustration programs such as Procreate on IOS.</p>
        <p>I am always available to help anyone with different art projects that they may have. I have many skills and have helped all walks of life with design, illustration, photography, costumes, and modeling.</p>
      </div>
    </div>
  )
};

export default About;
