import React from 'react';
import { Dialog, DialogContent, DialogContentText } from '@material-ui/core';


interface SplashProps {
  open: boolean
}
const Splash:React.FC<SplashProps> = (props) => {
  return (
    <Dialog open={props.open}>
      <DialogContent>
        <DialogContentText>{props.children}</DialogContentText>
      </DialogContent>
    </Dialog>
  );
};

export default Splash;
