import React from 'react';
import { useQuery } from '@apollo/client';
import HomeTile from './components/HomeTile';
import { CATEGORIES_WITH_ITEMS, ICategoriesWithItems } from './GraphQL/Categories';
import './styles/Home.css'
import ImgHeader from './images/header.jpg'


const Home: React.FC = () => {

  const { data } = useQuery<ICategoriesWithItems>(CATEGORIES_WITH_ITEMS);

  let index = 0;
  return (
    <div className="Home w-100">

      <div className="hero" style={{ backgroundImage: `url(${ImgHeader})` }}>
        <div className="hero-cover" />
        <div className="hero-title flex items-center justify-between">
          <h3 className="w-100 tc">The Untitled Arts</h3>
        </div>
      </div>

      <div className="flex flex-wrap">
        { data && data.categories.map((category) => {
          let thumbUrl = category.itemSet.length > 0 ? category.itemSet[0].thumbUrl : '';
          return (<HomeTile key={category.id}
                    index={index++}
                    category={category}
                    thumbUrl={thumbUrl} />
          )}
        )}
      </div>
    </div>
  );
};

export default Home;
