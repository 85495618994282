
export const formatDate = (date: string) => {
  return new Intl.DateTimeFormat("en-US", {
    year: "numeric",
    month: "long",
    day: "2-digit"
  }).format(Date.parse(date));
};

export const formatSlug = (str: string) => {
  return str
    .toLowerCase()
    .replaceAll(/[\s]/g, '-') // All spaces to hyphens
    .replaceAll(/[^a-zA-Z0-9-_]/g, ''); // All invalid characters ignored
};
