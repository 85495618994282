import React, { useEffect } from 'react';
import { Switch, Route, useHistory } from 'react-router-dom';
import ReactGA from 'react-ga';
import Admin from './Admin';
import About from './About';
import Contact from './Contact';
import Gallery from './Gallery';
import Home from './Home';
import Header from './components/Header';
import Footer from './components/Footer';
import { AuthProvider, useProvideAuth } from './Admin';
import { GA_TRACKING_CODE } from './constants';

if (GA_TRACKING_CODE) {
  ReactGA.initialize(GA_TRACKING_CODE);
  ReactGA.pageview(window.location.pathname);
}

const App:React.FC = () => {

  const auth = useProvideAuth();
  const history = useHistory();

  useEffect(() => {
    return history.listen((location) => {
      if (GA_TRACKING_CODE) {
        ReactGA.pageview(location.pathname);
      }
    });
  }, [history]);

  return (
    <AuthProvider value={auth}>

        <Header />

        <div style={{height: "5rem"}} />

        <Switch>
          <Route exact path="/">
            <Home />
          </Route>
          <Route path="/gallery/:slug/:itemSlug?">
            <Gallery />
          </Route>
          <Route path="/about">
            <About />
          </Route>
          <Route path="/contact">
            <Contact />
          </Route>
          <Route path="/admin">
            <Admin />
          </Route>
        </Switch>

        <Footer />

    </AuthProvider>
  );
}

export default App;
